#banner {
  background-image: url("./banner.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;

  #bannerOverlay {
    background-image: linear-gradient(
      234.77deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.67) 99.95%
    );
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  // CAROUSEL FADE
  .carousel-fade {
    .active.carousel-item-left,
    .active.carousel-item-right {
      transition: 1s ease opacity;
    }
  }
}
