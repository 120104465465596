@import './variables.scss';

@import '~bootstrap/scss/bootstrap.scss';


// TYPOGRAPHY

body {
  font-family: 'Roboto', sans-serif;
}

.responsive-h1 {
  line-height: normal;
  font-size: 2.5rem;
}

.responsive-h2 {
  line-height: normal;
  font-size: 2rem;
}

@media(min-width: 768px) {
  .responsive-h1, .responsive-h2 {
    font-size: 3rem;
  }
}

@media(min-width: 992px) {
  .responsive-h1, .responsive-h2 {
    font-size: 4rem;
  }
}

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}


// BUTTONS

.btn {
  min-width: 220px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 25px;
}

.btn-accent {
  color: white;
}
