.forecast-manual__input-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.forecast-manual__input {
  flex: 1;
}

@media (min-width: 576px) {
  .forecast-manual__input-container {
    width: 18rem;
    margin-left: auto;
    margin-right: auto;
  }
}
