@import '../../styles/variables.scss';

#checkList {
  line-height: 2;
  list-style: none;
}
#checkList li::before {
  font-family: 'Font Awesome\ 5 Free';
  content: "\f00c";
  color: $accent;
  font-weight: bold;
  display: inline-block; 
  width: 2rem;
  margin-left: -2rem;
}

#mud {
  width: 100%;
  height: 15px;
  background-image: url('./mud.png');
  background-position-x: -100px;
  background-repeat: repeat-x;
  background-position-y: bottom;
  margin-bottom: -1px;
}

#parallaxImage {
  margin-bottom: -5.5rem;
}
