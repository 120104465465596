.button-select {
  padding: 1.5rem .5rem;
  text-align: center;
  height: 100%;
  overflow-y: scroll;
}

.button-select__grid {
  display: inline-grid;
  grid-template-columns: repeat(2, max-content);
  gap: .5rem;
  width: 18.5rem;
}

.button-select__option,
.button-select__option_active {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 9rem;
  height: 6rem;
  border: none;
  padding: .5rem;
  text-align: center;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .23);
  cursor: pointer;
}
.button-select__option-image-wrapper {
  display: block;
  width: 100%;
  height: 70%;
}
.button-select__option-image {
  max-width: 100%;
  height: 100%;
}
.button-select__option-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30%;
  color: var(--predictor-color-primary, #3b4143);
  font-size: .9rem;
  font-weight: 700;
  line-height: 1;
}
.button-select__option:active,
.button-select__option_active:active {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .5) inset;
}
.button-select__option_active {
  background-color: var(--predictor-color-accent, #ffb332);
}
.button-select__option_active > .button-select__option-label {
  color: white;
}

@media (min-width: 576px) {
  .button-select__grid {
    width: 23rem;
  }

  .button-select__option,
  .button-select__option_active {
    width: 11.25rem;
    height: 7.5rem;
    padding: 1rem 1rem .5rem;
  }
}

@media (min-width: 768px) {
  .button-select__grid {
    grid-template-columns: repeat(3, max-content);
    width: 34.75rem;
  }
}

@media (min-width: 992px) {
  .button-select__grid {
    grid-template-columns: repeat(4, max-content);
    width: 46.5rem;
  }
}
