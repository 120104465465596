.predictor-progress {
  position: relative;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  border: none; /* for Firefox */
  background-color: #dedede;  /* for Firefox */
  color: var(--predictor-color-accent, #ffb332); /* for IE */
}

/* WebKit */
.predictor-progress::-webkit-progress-bar {
  background-color: #dedede;
}
.predictor-progress::-webkit-progress-value {
  background-color: var(--predictor-color-accent, #ffb332);
}

/* Firefox */
.predictor-progress::-moz-progress-bar {
  background-color: var(--predictor-color-accent, #ffb332);
}

/* IE */
.predictor-progress::-ms-fill {
  border: none;
}
