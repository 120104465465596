.step-frame {
  height: 100%;
}

.step-frame__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
  padding: 1rem;
  background-color: #fcfcfc;
}

@media (min-width: 576px) {
  .step-frame__content {
    padding: 1.5rem;
  }
}

@media (min-width: 768px) {
  .step-frame {
    padding: 2rem;
  }
  .step-frame__content {
    padding: 2rem;
  }
}
