.standardFlipCard {
    background: #3b525e;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 2em;
}

@media screen and (max-width: 1024px) { 
    .standardFlipCard {
        padding: 48px 0;
        margin: 0
    }
}

@media screen and (max-width: 600px) { 
    .standardFlipCard {
        padding: 12px 0;
        margin: 0
    }
}

.standardFlipCard .flipWrapper {
    max-width: 1000px;
    width: 100%;
}

.standardFlipCard .flipHeader {
    max-width: 1200px;
}

.standardFlipCard .flipWrapper, .standardFlipCard .flipHeader {
    width: 100%;
    margin: 12px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}


@media screen and (max-width: 1024px) {
    .standardFlipCard .flipWrapper, .standardFlipCard .flipHeader {
        width: 100%;
        justify-content: center;
    }
}

.standardFlipCard .flipHeader {
    justify-content: left;
}

.standardFlipCard .flipWrapper .flipReduced {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .standardFlipCard .flipWrapper .flipReduced {
      justify-content: center;
    }
}

@media screen and (max-width: 767px) {
    .standardFlipCard .flipWrapper .flipReduced {
        flex-direction: column;
      }
}

.standardFlipCard .flipHeader h1 {
    font-family: Roboto;
    font-size: 67px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.9;
    letter-spacing: 0.7px;
    color: #ffffff;
}

@media screen and (max-width: 1024px) {
    .standardFlipCard .flipHeader h1 {
        text-align: center;
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .standardFlipCard .flipHeader h1 {
        font-size: 40px;
    }
}

.standardFlipCard .flipHeader h1 span {
    font-weight: 300;
}

.standardFlipCard .flipWrapper .flipContainer {
    margin: 12px;
    padding: 12px;
    color: #ffffff;
    border: 1px solid #ffffff;
    height: 230px;
    width: 240px;
    transform-style: preserve-3d;
    transition: all 1s ease-in-out;
    position: relative;
}
@media screen and (max-width: 1024px) {
.standardFlipCard .flipWrapper .flipContainer {
        width: 160px;
        height: 150px;
        padding: 0;
    }
}

/* Hover Flip, currently disabled  => .standardFlipCard .flipWrapper .flipContainer:hover {
    transform: rotateY(180deg);
}
*/

.standardFlipCard h5 {
    font-family: Roboto;
    font-size: 25px;
    font-weight: 900;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

@media screen and (max-width: 1024px) {
    .standardFlipCard h5 {
        font-size: 21px;
        margin: 0;
    }
}

.standardFlipCard p {
    font-family: Roboto;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

@media screen and (max-width: 1024px) {
    .standardFlipCard p {
        font-size: 17px;
        line-height: 1.1;
        padding: 0 8px;
    }
}

.standardFlipCard img {
    max-width: 120px;
    height: 90px;
    margin: 12px;
}

@media screen and (max-width: 1024px) {
    .standardFlipCard img {
        max-width: 65px;
        height: 50px;
    }
}

.standardFlipCard .flipWrapper .flipContainer .side {
    backface-visibility: hidden;
    overflow: hidden;
}

.standardFlipCard .flipWrapper .flipContainer .back {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 12px;
    background: #ffffff;
    color: #3b525e;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .standardFlipCard .flipWrapper .flipContainer .back p {
    font-size: 21px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #3b525e;
    margin: 0;
  }

  .standardFlipCard .flipWrapper .flipContainer .strong {
      font-weight: 900;
  }