@import '../../../styles/variables.scss';

#traktorProgress {
  width: 100%;
  display: flex;
  align-items: center;

  .milestone {
    margin-top: 1px;
    color: white;
    &::before {
      font-family: 'Font Awesome\ 5 Free';
      content: '\f111';
      font-weight: normal;
    }

    &.filled {
      color: $accent;
      &::before {
        font-weight: bold;
      }
    }
  }

  .progress-line {
    width: 100%;
    height: 0;
    border-top: 3px dotted white;
    &.filled {
      border-top-color: $accent;
    }
    &.short {
      width: 87%;
    }
  }

  .tractor-icon {
    width: 22%;
  }
}
