.forecast {
  height: 100%;
  overflow-y: scroll;
}

.forecast__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: rgba(0, 0, 0, .25);
}

.forecast__spinner {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .forecast__spinner {
    margin-bottom: 3rem;
  }
}
