.predictor-navbar {
  display: flex;
  background-color: var(--predictor-color-primary, #3b4143);
}

.predictor-navbar__heading {
  flex: 1;
  display: block;
  text-align: center;
  padding: 1rem;
  color: white;
  font-size: 1.25rem;
  font-weight: 900;
}

.predictor-navbar__button-wrapper {
  display: none;  /* when visible - flex */
  align-items: center;
  justify-content: center;
  width: 7rem;
}
.predictor-navbar__button-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border: none;
  margin: 1rem;
  padding: 0;
  background: none;
  color: var(--predictor-color-accent, #ffb332);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.9;
}
@media (min-width: 768px) {
  .predictor-navbar__button-wrapper {
    display: flex;
  }
}
