:root {
  --predictor-color-primary: #3b4143;
  --predictor-color-accent: #ffb332;
}

.predictor {
  height: 100vh;  /* fallback */
  height: calc(var(--vh, 1vh) * 100);
  background-image: url('../img/backround_app.jpg');
  background-position: center;
  background-size: cover;
}

.predictor__navbar-underlay {
  position: fixed;
  width: 100%;
  height: 3rem;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: var(--predictor-color-primary, #3b4143);
}

.predictor__overlay {
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}

.predictor__content {
  height: 100%;
  padding-top: 3rem;
}

/* disabling bootstrap `.container` spacing */
@media (max-width: 991.98px) {
  .predictor__content {
    max-width: 100%;  /* overriding the `.container` `max-width` property */
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .predictor__navbar-underlay {
    display: none;
  }
  .predictor__content {
    padding-top: 5.5rem;
    padding-bottom: 4rem;
  }
}
