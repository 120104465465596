.frameContent {
  overflow-y: scroll;
}

.heading {
  max-width: 22rem;
  margin: 0 auto 1.5rem;
  font-size: 1.75rem;
}


@media (min-width: 992px) {
  .heading {
    max-width: 25rem;
    font-size: 2rem;
  }
}