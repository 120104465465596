#contact {
  background-image: url('./wheat.jpg');
  background-size: cover;
  background-position: center;
}

#contactOverlay {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.73));
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

#contactLabel {
  display: inline-block;
  position: relative;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
}
#contactLabel > .corner {
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-bottom: 16px solid #d08b1a;
  border-left: 13px solid transparent;
  z-index: -1;
}
