.checkbox-list {
  padding-bottom: .5rem !important;
}
@media (min-width: 576px) {
  .checkbox-list {
    padding-bottom: .75rem !important;
  }
}
@media (min-width: 768px) {
  .checkbox-list {
    padding-bottom: 1rem !important;
  }
}

/* List Element */
.checkbox-list__list {
  height: 100%;
  border-top: 1px solid #ebebeb;
  margin: 0;
  padding-left: 0;
  padding-right: 1rem;
  list-style: none;
  overflow-y: scroll;
}
.checkbox-list__list-item {
  border-bottom: 1px solid #ebebeb;
}
