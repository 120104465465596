@import '../../../styles/variables.scss';

#contactForm {
  input {
    background: none;
    border: none;
    border-bottom: 2px solid white;
    color: white;
    outline: none;
    padding: .25rem .5rem;
    width: 100%;

    &:focus {
      border-bottom-color: $accent;
    }
  }

  button[type="submit"] {
    background: none;
    border: none;
    padding: .75rem;
  }
}
