.custom-scrollbar {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, .18) #dedede;
  /* MS IE */
  scrollbar-face-color: rgba(0, 0, 0, .18);
  scrollbar-track-color: #dedede;
}

/* WebKit */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #dedede;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, .18);
}
