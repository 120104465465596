/* The container */
.custom-checkbox {
  position: relative;
  display: block;
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 3.25rem;
  font-size: 1.1rem;
  line-height: 1;
  cursor: pointer;
}

/* Checkbox textual label */
.custom-checkbox__label {
  color: var(--predictor-color-primary, #3b4143);
  font-size: 1.1rem;
  font-weight: 500;
}

/* Hide the browser's default checkbox */
.custom-checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

/* Create a custom checkbox */
.custom-checkbox__checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.5rem;
}

/* Create the checkmark/indicator */
.custom-checkbox__checkmark:after {
  content: '\f0c8';
  color: var(--predictor-color-primary, #3b4143);
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

/* Show the checkmark when checked */
.custom-checkbox__input:checked ~ .custom-checkbox__checkmark:after {
  content: '\f14a';
  color: var(--predictor-color-accent, #ffb332);
  font-weight: 700;
}
