.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#videoLabel {
  position: absolute;
  bottom: -25px;
  left: 51.5%;
  transform: translateX(-50%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
#videoLabel > .corner {
  position: absolute;
  bottom: 0px;
  left: -9px;
  width: 0;
  height: 0;
  border-top: 26px solid #4d636e;
  border-left: 10px solid transparent;
}
