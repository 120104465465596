.linkButton {
    padding: 12px;
    display: block;
    margin: 12px auto;
    width: 160px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #ffb332;
    background: #ffffff;
    color: #ffb332;
    text-transform: uppercase;
    font-weight: 600;
    transition: 0.25s ease-in-out;
    &:hover {
        border: 1px solid #000000;
        transition: 0.25s ease-in-out;
        text-decoration: none;
    }
}