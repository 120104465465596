.sign-up {
  height: 100%;
  overflow-y: scroll;
}

.sign-up__image {
  max-width: 15rem;
}
.sign-up__content-part_image {
  text-align: center;
  margin: 0 0 1.5rem 0;
}
.sign-up__content-part_text {
  text-align: center;
  max-width: 40rem;
  margin: auto;
}

.sign-up__heading {
  margin: 0 auto 1.5rem;
  font-size: 1.75rem;
}

.sign-up__checkbox-wrapper {
  max-width: 16rem;
  margin: 0 auto 1rem;
}

@media (min-width: 576px) {
  .sign-up__heading {
    font-size: 2rem;
  }
  .sign-up__checkbox-wrapper {
    max-width: none;
  }
}

@media (min-width: 992px) {
  .sign-up__content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
  }

  .sign-up__image {
    max-width: 100%;
  }
  .sign-up__content-part_image {
    width: 48%;
    margin: 0;
    padding-left: 1rem;
    padding-right: 3rem;
  }
  .sign-up__content-part_text {
    text-align: left;
    width: 52%;
  }
}
