.finish-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.finish-step__message {
  text-align: center;
  width: auto;
  max-width: 20rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.finish-step__button-block {
  text-align: center;
  margin-top: 2.5rem;
}
