.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-screen__spinner {
  color: rgba(0, 0, 0, .25);
}
@media (min-width: 768px) {
  .loading-screen__spinner {
    margin-bottom: 2rem;
  }
}
