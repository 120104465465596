.forecast-content {
  min-height: 100%;
  padding: 1rem .5rem;
  background-color: white;
  color: var(--predictor-color-primary, #3b4143);
}

.forecast-content__main {
  display: block;
  text-align: center;
  max-width: 45rem;
  padding: 1rem 0;
}

.forecast-content__stamp-wrapper {
  width: 6rem;
  height: 6rem;
  margin: 0 auto .75rem;
}
.forecast-content__stamp-image {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.forecast-content__heading {
  width: 18rem;
  margin: auto;
}

.forecast-content__text-large {
  display: inline;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.35;
}

.forecast-content__text-normal {
  font-size: 1rem;
  line-height: 1.5;
}

.forecast-content__price-block {
  margin: 2rem auto;
}

.forecast-content__price-text {
  display: block;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.25;
}

.forecast-content__feedback-text {
  display: block;
  width: 10rem;
  margin: auto;
  font-size: 1rem;
}

.forecast-content__button-block {
  margin: 1.5rem auto 1rem;
}

.forecast-content__hint-block {
  max-width: 45rem;
  padding: 0;
}
.forecast-content__paragraph {
  display: block;
  text-align: justify;
  padding: 0 .5rem;
  font-size: .8rem;
}
.forecast-content__text-warning {
  color: var(--predictor-color-accent, #ffb332);
  font-weight: 700;
}

@media (min-width: 576px) {
  .forecast-content__heading {
    width: auto;
  }
  .forecast-content__text-large {
    display: block;
    font-size: 1.5rem;
  }
  .forecast-content__text-normal {
    font-size: 1.2rem;
  }
  .forecast-content__price-text {
    display: inline;
  }
  .forecast-content__feedback-text {
    width: auto;
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  .forecast-content {
    padding: 2rem;
    background-color: transparent;
  }
  .forecast-content__main {
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .36);
    padding-top: 3rem;
    background-color: #fcfcfc;
  }
  .forecast-content__stamp-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -1.5rem;
    width: 8rem;
    height: 8rem;
    margin-bottom: 0;
  }
  .forecast-content__paragraph {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .forecast-content__stamp-wrapper {
    left: -4rem;
  }
}
