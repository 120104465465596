/* typography */
.predictor__heading-1 {
  margin-bottom: 0;
  color: var(--predictor-color-accent, #ffb332);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.predictor__text {
  color: var(--predictor-color-primary, #3b4143);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
}
.predictor__text_large {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
}


/* buttons */
.predictor__button {
  display: inline-block;
  text-align: center;
  min-width: 8.5rem;
  border: none;
  border-radius: 1.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .24);
  /*outline: none !important;*/
  padding: .5rem 1rem;
  background: none;
  font-size: 1rem;
  font-weight: 500;
}
.predictor__button:hover {
  text-decoration: none;
  cursor: pointer;
}
.predictor__button:active {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .18) inset;
}
.predictor__button_default {
  background-color: #ebebeb;
  color: var(
    --predictor-color-primary,
    #3b4143
  ) !important; /* using !important for :hover */
}
.predictor__button_accent {
  background-color: var(--predictor-color-accent, #ffb332);
  color: white !important;  /* using !important for :hover */
}

/* button link */
.predictor__button-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border: none;
  padding: 0;
  background: none;
  color: var(--predictor-color-accent, #ffb332);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.9;
}


/* forms */
.predictor__input {
  box-sizing: content-box;
  border: none;
  border-bottom: 1px solid #bbb;
  padding-bottom: 1px;
  outline: none;
  color: var(--predictor-color-primary, #3b4143);
}
.predictor__input::placeholder {
  color: #bbb;
  font-weight: 500;
}
.predictor__input:hover,
.predictor__input:focus {
  border-bottom-width: 2px;
  padding-bottom: 0;
}
.predictor__input:focus {
  border-bottom-color: var(--predictor-color-accent, #ffb332);
}

.predictor__input-outline {
  border: none;
  border-radius: 1.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
  padding: .5rem 1.5rem;
  background-color: #fcfcfc;
  color: var(--predictor-color-primary, #3b4143);
  font-size: 1rem;
}
