$primary: #3B4143;
$accent: #ffb232;

$theme-colors: (
  "accent": $accent
);

$btn-padding-y: .75rem;
$btn-border-width: 2px;

$navbar-dark-color: white;
$navbar-dark-hover-color: white;
$navbar-dark-active-color: white;

$modal-content-border-radius: 0;

