.search-bar {
  position: relative;
  margin-bottom: 1rem;
}

.search-bar__input {
  width: 100%;
  height: 3rem;
  border: none;
  border-radius: 1.5rem;
  padding-left: 4rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
  background-color: #fcfcfc;
  color: var(--predictor-color-primary, #3b4143);
}
.search-bar__input::placeholder {
  color: #3b525d;
  font-weight: 500;
}

.search-bar__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2rem;
  color: #3b525d;
}
