.predictor-footer {
  display: flex;
  padding: .75rem 1rem;
  background-color: var(--predictor-color-primary, #3b4143);
}

.predictor-footer__button {
  width: 7.25rem;
  height: 2.75rem;
  border: none;
  border-radius: 22px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
  /*outline: none !important;*/
  background-color: var(--predictor-color-primary, #3b4143);
  color: var(--predictor-color-accent, #ffb332);
  font-weight: 700;
}
.predictor-footer__button:active {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .5) inset;
}

@media (min-width: 768px) {
  .predictor-footer {
    display: none;
  }
}
