.aboutusContainer .contentContainer {
    max-width: 1200px;
    padding: 12px;
    display: block;
    margin: 12px auto;
    text-align: left;
}

@media screen and (max-width: 1024px) {
    .aboutusContainer .contentContainer {
        padding: 48px;
    }
}

@media screen and (max-width: 600px) {
    .aboutusContainer .contentContainer {
        padding: 24px;
    }
}

.aboutusContainer .paragraphs {
    display: block;
    max-width: 1000px;
}

.aboutusContainer .contentContainer h1 {
    font-family: Roboto;
    font-size: 67px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: 0.7px;
    color: #3b525e;
    margin: 0 0 12px 0;
    padding: 0 0 12px 0;
    max-width: 700px;
}

@media screen and (max-width: 767px) {
    .aboutusContainer .contentContainer h1 {
        font-size: 40px;
    }
}

.aboutusContainer .contentContainer h1 .light {
    font-weight: 300;
    line-height: 1.2;
}

.aboutusContainer .contentContainer .bold {
    color: #3b525e !important;
    font-weight: 900;
    text-decoration: underline !important;

}

@media screen and (max-width: 600px) {
    .aboutusContainer .contentContainer h1 .light {
        display: inline;
        letter-spacing: -1px;
    }
}
.aboutusContainer .contentContainer h5 {
    font-family: Roboto;
    font-size: 21px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3b525e;
    margin: 16px 0;
}

@media screen and (max-width: 600px) {
    .aboutusContainer .contentContainer h5 {
        font-size: 18px;
    }
}
.aboutusContainer .contentContainer .paragraphs > p {
    font-family: Roboto;
    font-size: 21px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3b525e;
}

@media screen and (max-width: 600px) {
    .aboutusContainer .contentContainer .paragraphs > p {
        font-size: 18px;
    }
}

.emailschreiben {
    margin: 64px 0;
}

@media screen and (max-width: 1024px) {
    .emailschreiben {
        margin: initial;
    }
}

.emailschreiben, .emailschreiben a {
    font-size: 21px;
    font-weight: 900 !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffb232 !important;
}

@media screen and (max-width: 600px) {
    .emailschreiben, .emailschreiben a {
        font-size: 18px;
    }
    .emailschreiben {
        color: #3b525e !important;
    }
}

.emailschreiben a {
    text-decoration: underline !important;
}

.pressContainer {
    border-top: 1px solid #3c525d;
    border-bottom: 1px solid #3c525d;
    padding: 12px 0;
    margin: 12px 0;
    display: inline-block;
}

.pressLogo {
    max-width: 200px;
}

.pressParagraph, .pressLogo {
    display: inline-block;
    vertical-align: middle;
}

.pressParagraph {
    margin: 12px;
    max-width: 250px;
}

.pressTextContainer .smallHeading {
    display: block;
    font-weight: 900 !important;
    line-height: 1.5;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #3b525e;
}

.pressTextContainer .regularPressText {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #3b525e;
    margin: 0;
}

.pressLogoContainer {
    display: inline-block;
    vertical-align: middle;
    margin: 8px 8px 8px 0px;
}

.pressTextContainer {
    display: inline-block;
    vertical-align: middle;
    margin: 8px;
    font-family: Roboto;
    max-width: 250px;
}

@media screen and (max-width: 600px) {
    .pressTextContainer {
        margin: 8px 8px 8px 0;
    }
}

.infoWrapper {
    padding: 2em; 
}

@media screen and (max-width: 1024px) {
    .infoWrapper {
        padding: 0;
        margin: 0;
    }
}

/* about us banner picture custom height */

@media screen and (max-width: 1024px) {
    #about-us-banner-picture {
        height: 60vh !important;
    }
}

@media screen and (min-width: 1025px) {
    #about-us-banner-picture {
        height: 65vh !important;
    }
}

@media screen and (min-width: 1441px) {
    #about-us-banner-picture {
        height: 70vh !important;
    }
}

@media screen and (min-width: 1441px) {
    #about-us-banner-picture {
        height: 80vh !important;
    }
}
