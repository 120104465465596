.helpContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 4px 12px;
    padding: 4px 4px;
}
@media screen and (max-width: 600px) {
    .helpContainer {
        padding: 0;
        margin: 0;
    }
}
.helpWrapper {
    width: 100%;
    padding: 4px;
    margin: 4px;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 600px) {
    .helpWrapper {
        margin: 0;
    }
}

.helpElement {
    width: 100%;
    padding: 4px;
    margin: 8px;
    text-align:left;
    max-width: 800px;
}

@media screen and (max-width: 600px) {
    .helpElement {
        width: auto;
    }
}

.helpElement h3 {
    font-family: Roboto;
    font-size: 31px;
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: normal;
    color: #3c525d;
    word-wrap: break-word;
}
@media screen and (max-width: 600px) {
    .helpElement h3 {
        font-size: 21px;
    }
}

.helpElement p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: normal;
    color: #3c515d;
    word-wrap: break-word;
}

.topHelpBar {
    width: 100%;
    padding: 4px 0;
    margin: 4px 0;
    cursor: pointer;
}
.hilfeCenter {
    margin: 0 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
@media screen and (max-width: 600px) {
    .hilfeCenter {
        margin: 0;
    }
}
.hilfeCenter h2 {
    text-align: left;
    font-family: Roboto;
    font-size: 31px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: normal;
    display: inline-block;
    margin: 0;
}
@media screen and (max-width: 600px) {
    .hilfeCenter h2 {
        line-height: 1.2;
        max-width: 200px;
        padding: 4px;
        margin: 4px;
    }
}
.hilfeContent {
    margin: 12px 0;
}
@media screen and (max-width: 600px) {
    .hilfeContent {
        width: 100%;
    }
}
.hilfeCenter h2 span {
    font-weight: 900;
}

.hilfeCenter img {
    width: 25px;
    height: 25px;
}

.spacerHilfe {
    display: block;
}

.hilfeLink {
    color: #ffb332 !important;
}

/* Buttons */
.weiterButton {
    color: #ffb232; 
    background: none;
    border: none;
    cursor: pointer;
}
.weiterButton:active, 
.weiterButton:focus 
{
    border: none;
    color: #ffb232; 
    outline: none;
 }

 .hilfeContent h2 {
    color: rgb(255, 178, 50);
    text-align: left;
    padding: 4px;
    font-size: 32px;
    margin: 8px;
    font-weight: 100;
 }

 .hilfeContent h2 span {
     font-weight: 700;
 }