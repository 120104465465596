#root {
  overflow: hidden;
}

.tempSpacer {
  height: 65px;
}

@media (min-width: 992px) {
  .tempSpacer {
    height: 88px;
  }
}

.datenschutz-li {
  list-style-type: none;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #3B4143;
  padding: 8px 0px;
  margin: 0;
}

.ds-headline {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #3B4143;
  font-weight: 700;
  padding: 8px 0px;
  margin: 0;
}

/* Subpages => Impressum, Datenschutz, notFound */
.subpageBody {
  flex: 1 0 auto;
}
.subpageBody nav {
  position: relative;
}
.subpageContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1 0 auto;
}
.notSupportedContent h3, .notSupportedContent p {
  text-align: center;
}

.datenschutzContent, .impressumContent, .notSupportedContent {
  text-align: left;
  max-width: 1200px;
  padding: 26px;
  margin: 26px;
}

@media screen and (max-width: 600px) {
  .datenschutzContent, .impressumContent, .notSupportedContent {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
.datenschutzContent ul {
  margin: 12px;
  padding: 4px;
}
.datenschutzContent ul li {
  line-height: 1.5;
  word-break: break-all;
}
.datenschutzContent h3, .impressumContent h3, .notSupportedContent h3 {
  color: #ffb332;
  font-weight: 900;
  font-size: 31px;
  margin: 12px;
  padding: 4px;
  word-break: break-all;
}
@media screen and (max-width: 600px) {
  .datenschutzContent h3, .impressumContent h3, .notSupportedContent h3 {
    font-size: 28px;
  }
}
/*custom break-point only for h3 for datenschutz */
@media screen and (max-width: 420px) {
  .datenschutzContent h3 {
    max-width: 180px;
  }
}

.datenschutzContent p, .impressumContent p, .notSupportedContent p {
  margin: 12px;
  padding: 4px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
}
.impressumContent {
  width: 100%;
  max-width: 820px;
}
.impressumData span {
  display: block;
}

.impressumHeading {
  display: block;
  font-weight: 600;
}

.impressumOneLine {
  display: block;
  font-weight: 400;
}

.impressumClose {
  position: absolute;
  top: 0;
  right: 0;
}

.impressumClose img {
  width: 25px;
  margin: 24px;
}

.cookieImg {
  width: 30px;
  margin: 8px;
}
@media screen and (max-width: 768px) {
  .cookieImg {
    vertical-align: top;
  }
}

.cookiebarContainer .closeContainer img {
  width: 18px;
  height: 18px;
  vertical-align: text-top;
}
.closeContainer {
  cursor: pointer;
  order: 1;
}
.cookiebarContainer button {
  background: #ffb232;
  padding: 2px 6px;
  border: 1px solid #ffb232;
  color: #ffffff;
  font-weight: 600;
}

.cookieTextContainer {
  margin: 0px 12px;
}
.contentCookieWrapper {
  order: 2;
}
@media screen and (max-width: 768px) {
  .cookieTextContainer {
    width: 80%;
  }
}

.closeWrapper {
  float: left;
  display: block;
}

/* Cookiebar */
.cookieLink {
  color: white !important;
  text-decoration-line: underline !important;
}

.cookiebarContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  opacity: 0.9;
  background-color: #3B4143;
  bottom: 30px !important;
  height: 50px;
  width: 100%;
  max-width: 740px;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
}
@media screen and (max-width: 768px) {
  .cookiebarContainer {
    height: auto;
    bottom: 0 !important;
    padding: 8px;
  }
}
@media screen and (max-width: 768px) {
  .cookiebarContainer > div  {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cookiebarContainer > * {
  display: inline-block;
}
.cookieText {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
}
