.bannerContainer {
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    flex-direction: 'column';
}

.contentrWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contentrWrapper h1 {
    font-family: Roboto;
    font-size: 90px;
    font-weight: 900;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}
@media screen and (max-width: 768px) {
    .contentrWrapper  h1 {
        font-size:58px;
    }
}
@media screen and (max-width: 600px) {
    .contentrWrapper  h1 {
        font-size:42px;
    }
}

.contentrWrapper p {
    font-family: Roboto;
    font-size: 21px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.hecktorYellow {
    width: 220px;
    height: 50px;
    border-radius: 30px;
    background-color: #ffb232;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
    margin: 20px;
}

@media (hover: hover) {
    .hecktorYellow:hover {
        background-color: #465357;
        transition: 0.5s;
    }
}

.hecktorYellow span {
    color: #ffffff;
    border: none;
    font-weight: bold;
    letter-spacing: 1.5px;
    font-family: Roboto, Lato;
    transition: 0.5s;
}

.bannerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}