/* custom navbar styles */

.app-navbar {
  position: fixed;
  z-index: 2;
  width: 100%;
  padding-right: .25rem;
  transition: background 0.35s ease;
}

.app-navbar__brand {
  padding: 0;
}
.app-navbar__brand-image {
  height: 1.5rem;
}

@media (min-width: 576px) {
  .app-navbar {
    padding-right: 1rem;
  }
  .app-navbar__brand {
    margin-left: 1rem;
  }
}

@media (min-width: 992px) {
  .app-navbar {
    padding-right: 1.5rem;
  }
  .app-navbar__brand-image {
    height: 2rem;
  }
}
