.standardVerticalTimeline {
    background: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 2em;
}

@media screen and (max-width: 1024px) {
    .standardVerticalTimeline {
        padding: 48px 0;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .standardVerticalTimeline {
        padding: 12px 0;
        margin: 24px 0;
    }
}

.standardVerticalTimeline .verticalTimelineWrapper {
    max-width: 1000px;
    width: 100%;
}

.standardVerticalTimeline .verticalTimelineHeader {
    max-width: 1200px;
}

@media screen and (max-width: 600px) {
    .standardVerticalTimeline .verticalTimelineWrapper, .standardVerticalTimeline .verticalTimelineHeader {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.standardVerticalTimeline .verticalTimelineWrapper, .standardVerticalTimeline .verticalTimelineHeader {
    width: 100%;
    margin: 12px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

@media screen and (max-width: 1024px) {
    .standardVerticalTimeline .verticalTimelineWrapper, .standardVerticalTimeline .verticalTimelineHeader {
        width: 100%;
        justify-content: center;
    }
}

.standardVerticalTimeline .verticalTimelineHeader {
    justify-content: left;
}

.standardVerticalTimeline .verticalTimelineWrapper .verticalTimelineReduced {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.standardVerticalTimeline .verticalTimelineHeader h1 {
    font-family: Roboto;
    font-size: 67px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.9;
    letter-spacing: 0.7px;
    color: #3b525e;
}

@media screen and (max-width: 1024px) {
    .standardVerticalTimeline .verticalTimelineHeader h1 {
        text-align: center;
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .standardVerticalTimeline .verticalTimelineHeader h1 {
        font-size: 40px;
    }
}

.standardVerticalTimeline .verticalTimelineHeader h1 span {
    font-weight: 300;
}

.standardVerticalTimeline .verticalTimelineWrapper .verticalTimelineContainer {
    margin: 0;
    padding: 0;
    transition: all 1s ease-in-out;
    width: 100%;
}

.standardVerticalTimeline h5 {
    font-family: Roboto;
    font-size: 25px;
    font-weight: 900;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #3b525e;
}

.standardVerticalTimeline p {
    font-family: Roboto;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #3b525e;
}

@media screen and (max-width: 600px) {
    .standardVerticalTimeline p {
        font-size: 18px;
    }
}

  .standardVerticalTimeline .verticalTimelineWrapper .verticalTimelineContainer .back p {
    font-size: 21px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #3b525e;
    margin: 0;
  }

  .standardVerticalTimeline .verticalTimelineWrapper .verticalTimelineContainer .strong {
      font-weight: 900;
  }

  /* */

  .verticalTimelineCard {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .contentCol {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; 
    justify-content: center;
    align-items: center;
  }

  .mainPicture {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .picCont {
    display: flex;
    flex-basis: 50%;
    width: 50%;
    padding: 18px 0;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  
  @media screen and (max-width: 1024px) {
    .picCont {
        flex-basis: 100%;
    }
  }

  .textWrapper {
    padding: 18px;
    margin: 18px;
  }

  @media screen and (max-width: 1024px) {
    .textWrapper {
        padding: 0 18px;
        margin: 0 18px;
        width: 400px;
      }
  }

  @media screen and (max-width: 600px) {
    .textWrapper {
        padding: 0 18px;
        margin: 0 18px;
        width: 100%;
      }
  }

  .textCont {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding: 18px 0;
  }

  @media screen and (max-width: 1024px) {
    .textCont {
        flex-basis: 100%;
        align-items: center;
        justify-content: center;
        align-content: center;
    }
  }

  .textCont span {
      background: #ffb232;
      padding: 4px 12px;
      color: #ffffff;
      display: inline-block;
      font-size: 33px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: 0.5px;
  }

  .textCont h5, .textCont p {
      text-align: left;
  }
  @media screen and (max-width: 1024px) {
    .textCont h5, .textCont p {
        text-align: center;
    }
  }

  .arrowPicture {
    margin-top: 16px;
}
  
@media screen and (max-width: 1024px) {
    .arrowPicture {
        margin-top: 0;
        margin-bottom: 12px;
    }
}

.standardVerticalTimeline .mainPicture img {
    /* width: 100%; */
    /* height: 100%; */
    max-height: 250px;
    max-width: 250px;
    padding: 18px;
}

@media screen and (max-width: 1024px) {
    .standardVerticalTimeline .mainPicture img {
        max-height: 250px;
        max-width: 250px;
        padding: 0;
    }
    #timeline2 .mainPicture img {
        max-height: 250px !important;
        max-width: 250px !important; 
        padding: 0;
    }
    #timeline3 .mainPicture img {
        max-height: 250px !important;
        max-width: 250px !important; 
        padding: 0;
    }
}

@media screen and (max-width: 600px) {
    .standardVerticalTimeline .mainPicture img {
        max-height: 200px;
        max-width: 200px;
    }
    #timeline2 .mainPicture img {
        max-height: 200px !important;;
        max-width: 200px !important;
        padding: 0;
    }
    #timeline3 .mainPicture img {
        max-height: 200px !important;
        max-width: 200px !important; 
        padding: 0;
    }
}

.standardVerticalTimeline .arrowPicture img {
    max-width: 50px;
}

/* Animations */

/* Animations do not work on safari because it doesnt support getBoundingClientRect()
@supports (-webkit-overflow-scrolling: touch) {
    .verticalTimelineContainer {
        opacity: 1;
    }
}
@media screen and (min-width: 1025px) {
  .verticalTimelineContainer:nth-child(2), .verticalTimelineContainer:nth-child(4) {
    opacity: 0;
    transform: translateX(-10%);
  }
  .verticalTimelineContainer:nth-child(1), .verticalTimelineContainer:nth-child(3) {
    opacity: 0;
    transform: translateX(10%);
  }
  .animatedRow {
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    opacity: 1 !important;
    transform: translateX(0%) !important;
  }
}
*/
