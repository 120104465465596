/* The container */
.custom-checkbox-2 {
  position: relative;
  display: inline-block;
  text-align: left;
  margin: 0;
  padding-left: 1.75rem;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.custom-checkbox-2__input {
  position: absolute;
  left: 0;
  opacity: 0;
}

/* Create a custom checkbox */
.custom-checkbox-2__checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

/* Create the checkmark/indicator */
.custom-checkbox-2__checkmark:after {
  content: '\f0c8';
  color: var(--predictor-color-primary, #3b4143);
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

/* Show the checkmark when checked */
.custom-checkbox-2__input:checked ~ .custom-checkbox-2__checkmark:after {
  content: '\f14a';
  color: var(--predictor-color-accent, #ffb332);
  font-weight: 700;
}
