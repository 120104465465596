#operatorCard {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 12rem;
  z-index: 2;
  font-weight: 500;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px 0 0 5px;
}

#operatorCard img {
  width: 6rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
